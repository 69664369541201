<div class="sidenav-header">
  <h1>JOINDRE L'ASSISTANCE MYBUSINESS</h1>
  <button type="button" class="close-btn" (click)="close()">
    <img src="assets/images/close-white.svg" alt="" />
  </button>
</div>
<p class="sidenav-description">
  Pour vos réclamations et demandes d’assistance
</p>
<div class="sidenav-body-content">
  <app-footer-elem
    image="assets/images/icon-time.svg"
    title="Du Lundi au Vendredi"
    description="Horaires : Ramadan De 9H à 16H / Hors Ramadan: De 8H30 à 18H"
    color="#FFE8F8"
  ></app-footer-elem>
  <app-footer-elem
    image="assets/images/call-national.svg"
    title="APPEL DEPUIS LE MAROC"
    description="4243"
    color="#F2E8FF"
  ></app-footer-elem>
  <app-footer-elem
    image="assets/images/call-international.svg"
    title="APPEL DEPUIS L'INTERNATIONAL"
    description="00212 522 42 42 43"
    color="#E8FAFF"
  ></app-footer-elem>
</div>
<img class="sidnav-art" src="assets/images/sidnav-art.svg" alt="" />
