import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private sidebarState = new Subject<string>();

  // Observable pour écouter les changements d'état
  sidebarState$ = this.sidebarState.asObservable();

  openSidebar(id: string) {
    this.sidebarState.next(id);
  }

  closeSidebar(id: string) {
    this.sidebarState.next(id);
  }
}
