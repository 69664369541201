import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppIframeComponent } from './components/app-iframe/app-iframe.component';
import { BeneficiariesSignedComponent } from './modules/provision-module/beneficiaries/signed/beneficiaries-signed.component';
import { BeneficiariesToSignComponent } from './modules/provision-module/beneficiaries/to-sign/beneficiaries-to-sign.component';
import { PublicRootComponentComponent } from './components/common/public-root-component/public-root-component.component';
import { SecureRootComponentComponent } from './components/common/secure-root-component/secure-root-component.component';
import { InitBeneficiaryComponent } from './modules/provision-module/beneficiaries/init-beneficiary/init-beneficiary.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { SoftTokenComponent } from './components/soft-token/soft-token.component';
import { AuthGuard } from './core/auth-utils/guards/auth.guard';
import { ProvisionDashboardComponent } from './modules/provision-module/provision/provision-dashboard/provision-dashboard.component';
import { ProvisionSignedComponent } from './modules/provision-module/provision/signed/provision-signed.component';
import { TransferDashboardComponent } from './modules/external-transfer/transfer-dashboard/transfer-dashboard.component';
import { SoftTokenDetailsComponent } from './modules/soft-token-module/soft-token-popin/soft-token-details/soft-token-details.component';
import { OnBoadingFirstStepComponent } from './modules/soft-token-module/on-boading-first-step/on-boading-first-step.component';
import { RefreshPageComponent } from './modules/shared-module/refresh-page/refresh-page/refresh-page.component';
import { SoftTokenNewComponent } from './components/soft-token-new/soft-token-new.component';
import { SoftTokenActivateComponent } from './components/soft-token-activate/soft-token-activate.component';
import { BalanceSheetGuard } from './core/auth-utils/guards/balance-sheet.guard';
import { SoftTokenGuard } from './core/auth-utils/guards/soft-token.guard';
import { CardSellGuard } from './core/auth-utils/guards/card-sell.guard';
import { ESignGuard } from './core/auth-utils/guards/e-sign.guard';

import { SellCardListComponent } from './modules/sell-card/sell-card-list/sell-card-list.component';
const provisionRoutes = {
  path: 'provision',
  canActivate: [AuthGuard],
  data: {
    roles: ['PROVISION'],
  },
  children: [
    {
      path: '',
      component: ProvisionDashboardComponent,
    },
    {
      path: 'signed',
      component: ProvisionSignedComponent,
    },
    {
      path: 'beneficiary',
      component: InitBeneficiaryComponent,
    },
    {
      path: 'beneficiaries/to-sign',
      component: BeneficiariesToSignComponent,
    },
    {
      path: 'beneficiaries/signed',
      component: BeneficiariesSignedComponent,
    },
  ],
};

const externalTransferRoutes = {
  path: 'external-transfer',
  children: [
    {
      path: '',
      canActivate: [AuthGuard],
      data: {
        roles: ['EXTERNAL_TRANSFER', 'OTHER_CHANEL'],
      },
      component: TransferDashboardComponent,
    },
  ],
};

const routes: Routes = [
  {
    path: 'public',
    component: PublicRootComponentComponent,
    children: [
      { path: 'soft-token', component: SoftTokenComponent },
      {
        path: 'on-boarding',
        loadChildren:
          './modules/soft-token-new-onboarding/soft-token-new-onboarding.module#SoftTokenNewOnboardingModule',
      },
    ],
  },

  {
    path: '',
    component: SecureRootComponentComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
      },
      { path: 'toapp/:code', component: AppIframeComponent },
      { path: 'onBoardingSoftToken', component: OnBoadingFirstStepComponent },
      { path: 'detailSoftToken', component: SoftTokenDetailsComponent },
      { path: 'detailSoftTokens', component: SoftTokenNewComponent },
      {
        path: 'onBoardingSoftTokens',
        component: SoftTokenActivateComponent,
        canActivate: [SoftTokenGuard],
      },
      {
        path: 'reporting',
        component: ReportingComponent,
      },
      externalTransferRoutes,
      {
        path: 'direct-debit',
        loadChildren:
          './modules/direct-debit/direct-debit.module#DirectDebitModule',
      },
      {
        path: 'escale-pro',
        loadChildren: './modules/escale-pro/escale-pro.module#EscaleProModule',
      },
      {
        path: 'consult-cards',
        loadChildren:
          './modules/consult-cards/consult-cards.module#ConsultCardsModule',
      },
      {
        path: 'soge-valeur',
        loadChildren:
          './modules/soge-valeur/soge-valeur.module#SogeValeurModule',
      },
      /* {
        path: 'sell-card',
        loadChildren: './modules/sell-card/sell-card.module#SellCardModule',
      },*/
      /*  {
        path: 'e-sign',
        loadChildren: './modules/e-sign/e-sign.module#ESignModule',
        canActivate: [ESignGuard],
      },*/
      {
        path: 'balance-sheet',
        loadChildren:
          './modules/balance-sheet/balance-sheet.module#BalanceSheetModule',
        canActivate: [BalanceSheetGuard],
      },
      {
        path: 'refreshPath/:page',
        component: RefreshPageComponent,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
