<div class="transfer-container">
  <h2 class="title-heading">RECHARGE CARTE ESCALE PRO</h2>
  <div class="tabs-container" *ngIf=" userCanConsult && userCanIniciate">
    <div class="tabs-header">
      <button *ngIf="userCanIniciate" class="tab-item" (click)="selectTab('INIT')" [class.active]="isActive('INIT')">
        <img alt="add-icon" src="../../../../assets/images/add.svg" />
        Nouvelle recharge
      </button>
      <ng-container *ngIf="userCanConsult">
        <button class="tab-item" (click)="selectTab('TO-SIGN')" [class.active]="isActive('TO-SIGN')">
          <img alt="add-icon" src="../../../../assets/images/suivi.svg" />
          Suivi des recharges
        </button>
        <button class="tab-item" (click)="selectTab('HISTORY')" [class.active]="isActive('HISTORY')">
          <img alt="add-icon" src="../../../../assets/images/historique.svg" />
          Historique des recharges
        </button>
      </ng-container>
    </div>
    <div class="tabs-content">
      <app-ep-init style="position: static" *ngIf="isActive('INIT')"></app-ep-init>
      <app-ep-to-sign [userCanSign]="userCanSign" [userCanConsult]="userCanConsult"
        *ngIf="isActive('TO-SIGN')"></app-ep-to-sign>
      <app-ep-history *ngIf="isActive('HISTORY')"></app-ep-history>
      <div *ngIf="isActive('ROLES-ERROR')">
        <div></div>
      </div>
    </div>
  </div>
  <div *ngIf="checkUser">



    <app-message-popin [openPopinFlag]="true" mode="" [message]="messageError" [success]="false"
      (popinClosedEvent)="closePopin()"></app-message-popin>
  </div>
</div>