import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer-elem',
  templateUrl: './footer-elem.component.html',
  styleUrls: ['./footer-elem.component.scss']
})
export class FooterElemComponent implements OnInit {
  @Input() image:string;
  @Input() title:string;
  @Input() description:string;
  @Input() color:string;
  constructor() { }

  ngOnInit() {
  }

}

