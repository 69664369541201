import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Client } from 'src/app/models/client';
import { UserConfig } from 'src/app/models/userConfig';
import { AccountService } from 'src/app/service/account.service';
import { ClientService } from 'src/app/service/client.service';
import { UserConfigService } from 'src/app/service/user-config-service';
import { UserClientConfigService } from 'src/app/service/user-client-config.service';
import { SoftTokenClientsService } from 'src/app/service/soft-token-clients.service';
import { SoftTokenPopinComponent } from '../../soft-token-module/soft-token-popin/soft-token-popin.component';
import { AccountBalanceDto } from '../models/AccountBalanceDto';
import { OverdraftAuthorizationDto } from '../models/OverdraftAuthorizationDto';
import { FeatureFlippingService } from '../../../service/feature-flipping.service';
import { Features } from '../../../models/features';
import { map, switchMap } from 'rxjs/operators';
import { DialogCommonComponent } from '../../popin/dialog-common/dialog-common.component';
import { forkJoin } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import {
  selectConnectedUser,
  selectSelectedCustomerNumber,
  selectUserSoftToken,
} from 'src/app/core/store/state/user';
import { User } from 'src/app/models/user';
import { SoftClientList } from 'src/app/models/soft-client-list';
import { Subscription } from 'rxjs';
import { SidenavComponent } from '../footer/sidenav/sidenav.component';
import { NgDialogAnimationService } from './d.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  accountList: Array<AccountBalanceDto> = [];
  overDraftsList: Array<OverdraftAuthorizationDto> = [];
  accountsAreLoaded = false;
  overDraftsAreLoaded = false;
  clients: Array<Client>;
  numberMaxOfSoftToken: number;
  userConfig: UserConfig;
  isAccountsEnabled: boolean;
  isCotationEnabled: boolean;
  isAuthorizationLineEnabled: boolean;
  popup_news:boolean=false;
  popup_contact:boolean=false;
  selectConnectedUser$ = this.store.select(selectConnectedUser);
  selectSelectedCustomerNumber$ = this.store.select(
    selectSelectedCustomerNumber
  );
  user: User;
  selectUserSoftToken$ = this.store.select(selectUserSoftToken);
  hasSoftToken: Boolean;
  softClientList: SoftClientList;
  selectConnectedUserSub: Subscription;
  balanceSheetCheckPopUpSub: Subscription;
  numberOfSoftTokenSub: Subscription;

  constructor(
    public dialog: NgDialogAnimationService,
    private userConfigService: UserConfigService,
    private userClientConfigService: UserClientConfigService,
    private clientService: ClientService,
    private accountService: AccountService,
    private SoftTokenClientsService: SoftTokenClientsService,
    private featureFlippingService: FeatureFlippingService,
    private store: Store<State>
  ) {}

  ngOnDestroy(): void {
    this.dialog.closeAll();
    this.balanceSheetCheckPopUpSub && this.selectConnectedUserSub.unsubscribe();
    this.balanceSheetCheckPopUpSub &&
      this.balanceSheetCheckPopUpSub.unsubscribe();
    this.numberOfSoftTokenSub && this.numberOfSoftTokenSub.unsubscribe();
  }

  SideApp(){
    this.dialog.open(SidenavComponent, {
      width: '100%',
      backdropClass: 'assistanceActionModal',
      animation:{to:"left"},
    });
  }
  ngOnInit(): void {
    let customerNumber = '';
    this.selectUserSoftToken$.subscribe(
      (hasSoftToken) => (this.hasSoftToken = hasSoftToken)
    );
    this.selectConnectedUserSub = this.selectConnectedUser$
      .pipe(
        switchMap((user) =>
          this.selectSelectedCustomerNumber$.pipe(
            map((customerNumber) => [user, customerNumber])
          )
        )
      )
      .subscribe(([user, customerNumber]) => {
        if (user != null && customerNumber) {
          this.user = user;
          customerNumber = customerNumber;
          this.getEnabledFeatures();
          this.balanceSheetCheckPopUp(customerNumber);
        }
      });
  }

  balanceSheetCheckPopUp(customerNumber) {
    this.balanceSheetCheckPopUpSub = this.userClientConfigService
      .balanceSheetCheckPopUp(customerNumber, true)
      .subscribe((value) => {
        if (value) {
          let year = new Date().getFullYear() - 1;
          this.dialog.open(DialogCommonComponent, {
            disableClose: true,
            data: {
              headerText: 'Campagne Collecte des Bilans Fiscaux',
              messageText:
                "  Nous vous remercions  d'importer  le bilan fiscal " +
                year +
                ' de votre entreprise <br>' +
                'dans le cadre du renouvellement de vos dossiers de crédits',
              buttonText: 'IMPORTER MON BILAN',
              redirection: '/balance-sheet',
              image: '/assets/images/campagne-bilans -fiscaux.png',
              flag: 'BALANCE_SHEET',
            },
          });
        } else {
          this.openSoftTokenPopinIfUserDoesntHaveSoftToken();
        }
      });
  }
  getEnabledFeatures() {
    this.featureFlippingService
      .getEnabledFeatures()
      .subscribe((resp: String[]) => {
        if (resp != null && resp.length != 0) {
          this.isAccountsEnabled = resp.includes(Features.ACCOUNTS);
          this.isCotationEnabled = resp.includes(Features.COTATIONS);
          this.isAuthorizationLineEnabled = resp.includes(
            Features.AUTHORIZATION_LINE
          );
        }
      });
  }

  /*
  openSoftTokenPopinIfUserDoesntHaveSoftToken(): void {
    forkJoin(
      this.SoftTokenClientsService.checkSoftTokenClients(),
      this.userConfigService.findMaxOfnumberOfSoftToken(),
      this.userConfigService.findUserConfigByUser(this.user.username)
    ).subscribe((res) => {
      const hasoftTokens = this.hasSoftToken;
      if (res[0]) {
        let hideSoftToken = res[2].showSoftTokenPopup;
        if (
          hasoftTokens != null &&
          hideSoftToken === false &&
          res[2].numberOfSoftTokenPopupDisplay <= Number(res[1])
        ) {
          this.openSoftTokenPopin();
        }
      }
    });
  }*/
  openSoftTokenPopinIfUserDoesntHaveSoftToken(): void {
    this.clientService.getClients().subscribe((res) => {
      let customersNumber = res.map((result) => result.customerNumber);
      this.softClientList = new SoftClientList();
      this.softClientList.customerNumbers = customersNumber;

      this.SoftTokenClientsService.checkSoftTokenClients(
        this.softClientList
      ).subscribe((soft) => {
        if (soft) {
          const hasoftTokens = localStorage.getItem('hasSoftToken');
          this.userConfigService
            .findMaxOfnumberOfSoftToken()
            .subscribe((result) => {
              this.numberMaxOfSoftToken = Number(result);
            });
          this.userConfigService
            .findUserConfigByUser(localStorage.getItem('username'))
            .subscribe((userConfig) => {
              this.userConfig = userConfig;
              let hideSoftToken = this.userConfig.showSoftTokenPopup;
              if (
                hasoftTokens != null &&
                hasoftTokens === 'false' &&
                hideSoftToken === false &&
                this.userConfig.numberOfSoftTokenPopupDisplay <=
                  this.numberMaxOfSoftToken
              ) {
                this.openSoftTokenPopin();
              }
            });
        }
      });
    });
  }

  openSoftTokenPopin(): void {
    this.numberOfSoftTokenSub = this.userConfigService
      .numberOfSoftToken(this.user.username)
      .subscribe(() => {
        this.dialog.open(SoftTokenPopinComponent, null);
      });
  }
}
