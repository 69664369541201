<div class="applications-container">
  <div class="applications-container-header">
    <h1>ACCÉDEZ À VOS <span>SERVICES</span></h1>
  </div>
  <app-applications></app-applications>
  <div class="side-actions" (click)="SideApp()">
    <div class="assistance-action">
      <img
        src="../../../../assets/images/call-assistance.svg"
        alt=""
        srcset=""
      />
    </div>
  </div>
  <!-- <mat-menu
    #belowMenu="matMenu"
    yPosition="below"
    xPosition="before"
    class="actionsContact"
  >
    <h1>JOINDRE L'ASSISTANCE</h1>
    <a (click)="belowMenu.close()">
      <img
        class="close-icone"
        src="../../../../assets/images/1.png"
        alt=""
        srcset=""
      />
    </a>
    <p>
      L'assistance My Business est disponible pour toute information ou
      réclamation
    </p>
    <app-footer-elem
      image=""
      title="APPEL DEPUIS LE MAROC"
      description="4243"
    ></app-footer-elem>
    <app-footer-elem
      image=""
      title="APPEL DEPUIS L'INTERNATIONAL"
      description="00 22  522 42 42 43"
    ></app-footer-elem>
    <app-footer-elem
      image=""
      title="Horaires"
      description="DEPUIS Lundi au Vendredi de 8H30 à 18h00"
    ></app-footer-elem>
  </mat-menu> -->
</div>
<div class="infos-container">
  <app-footer-list></app-footer-list>
</div>
