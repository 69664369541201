import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @ViewChild('closebutton') closebutton;

  constructor(private fb: FormBuilder, 
              public dialogRef: MatDialogRef<SidenavComponent>
  ) { }

  public ngOnInit() {
    
  }

  public onSave() {
    this.closebutton.nativeElement.click();
  }

  public close(){
    this.dialogRef.close();
  }
}
