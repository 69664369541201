import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdvisorExpertHistoryService } from '../../service/advisor-expert-history.service';
import { AdvisorService } from '../../service/advisor.service';
import { ExpertService } from '../../service/expert.service';
import { QrImageService } from '../../service/qr-image.service';
import { AdvisorExpertHistory } from 'src/app/models/advisorExpertHistory';
import { CafExpertService } from 'src/app/service/caf-expert.service';
import { Advisor } from 'src/app/models/advisor';
import { QrImage } from 'src/app/models/qrImage';
import { Expert } from 'src/app/models/expert';
import { EmailExpert } from 'src/app/models/emailExpert';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Page } from '../../models/page';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CafExpert } from 'src/app/models/CafExpert';

import { AssitanceDetailRequestComponent } from 'src/app/modules/dashboard/assistance/assitance-detail-request/assitance-detail-request.component';

import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { selectSelectedCustomerNumber } from 'src/app/core/store/state/user';
import { ClientService } from 'src/app/service/client.service';
import { CustomerFreeAttributes } from 'src/app/models/customerFreeAttributes';
import { AccountCustomerDetails } from 'src/app/models/AccountCustomerDetails';
import { SidebarService } from 'src/app/service/sidebar.service';

@Component({
  selector: 'app-experts',
  templateUrl: './experts.component.html',
  styleUrls: ['./experts.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({ opacity: 1, visibility: 'visible', height: '160px' })
      ),
      state('close', style({ opacity: 0, visibility: 'hidden', height: '0' })),
      transition('open  => close', [animate('0.2s')]),
      transition('close => open', [animate('0.1s')]),
    ]),
  ],
})
export class ExpertsComponent implements OnInit {
  public isOpen: number;
  myControl = new FormControl('');

  form = new FormGroup({
    subject: new FormControl('', [Validators.required, Validators.required]),
    message: new FormControl('', [Validators.required, Validators.required]),
    subjectAdvisor: new FormControl('', [
      Validators.required,
      Validators.required,
    ]),
    messageAdvisor: new FormControl('', [
      Validators.required,
      Validators.required,
    ]),
  });
  formExpert = new FormGroup({
    subjectExpert: new FormControl({ value: '', disabled: true }),
    messageExpert: new FormControl({ value: '', disabled: true }),
    subjectAdvisor: new FormControl({ value: '', disabled: false }),
    messageAdvisor: new FormControl({ value: '', disabled: false }),
  });
  showAdvisor: boolean = false;
  askAdvisor: number;
  page = new Page();
  managerSelect: string;
  advisor: Advisor;
  suivant: boolean;
  expert: Expert;
  cafExpert: CafExpert;
  qrImage: QrImage;
  experts: Expert[];
  expertSelectionner: Expert;
  cafExpertStatic: CafExpert[];
  cafExperts: CafExpert[];
  cafExpertSelectionner: CafExpert;
  advisorExpertHistorys: AdvisorExpertHistory[] = [];
  managers = ['conseiler', 'Expert'];
  url: SafeUrl;
  width = 90;
  height = 90;
  succesMessage: string;
  errorMessage: string;
  managerLabel: string;
  sendCheck: boolean;
  popup: boolean;
  index: string;
  customerNumberSelected: string = '';
  selectedIndex: number = 0;
  advisorExpertHistorySelected: AdvisorExpertHistory;
  id: string;
  customerFreeAttributes: CustomerFreeAttributes;

  constructor(
    public dialog: MatDialog,
    private advisorService: AdvisorService,
    private sidebarService: SidebarService,
    private qrImageService: QrImageService,
    private expertService: ExpertService,
    private advisorExpertHistoryService: AdvisorExpertHistoryService,
    private CafExpertService: CafExpertService,
    private sanitizer: DomSanitizer,
    private store: Store<State>
  ) {
    this.page.init();
    this.page.totalElements = 5;
  }
  stepNumberAdvisor: number = 0;
  stepNumberExpert: number;
  stepNumber: number;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);

  ngOnInit(): void {
    this.askAdvisor = null;
    this.formExpert.setValue({
      messageExpert: '',
      subjectExpert: '',
      subjectAdvisor: '',
      messageAdvisor: '',
    });
    this.suivant = true;

    this.expertService.findExpert().subscribe((value) => {
      this.experts = value;
      this.experts.sort(function (a, b) {
        return a.ordre - b.ordre;
      });
    });

    this.stepNumber = -1;
    this.formExpert.get('messageExpert').disable;
    this.stepNumberExpert = 0;

    this.selectedCustomerNumber$.subscribe((customNumber) => {
      if (customNumber && customNumber !== this.customerNumberSelected) {
        /* this.clientService
          .getClientCodeMarche(customNumber)
          .subscribe((result) => {
            this.customerFreeAttributes =
              this.getCustomerFreeAttributeWithCodeM(result);
            if (
              this.customerFreeAttributes.alphaNumericValue == '04' ||
              this.customerFreeAttributes.alphaNumericValue == '05'
            ) {
              this.askAdvisor = 0;
              this.showAdvisor = true;
            } else {
              this.showAdvisor = false;
              this.askAdvisor = 2;
              this.nextStep();
            }
          });*/

        this.customerNumberSelected = customNumber;
        this.showAdvisor = false;
        this.askAdvisor = 2;
        this.nextStep();
        this.showHistorique();
        //    this.chargeAdvisor();
      }
    });
    this.sidebarService.sidebarState$.subscribe((id) => {
      this.stepNumberExpert = 0;
      this.stepNumberAdvisor = 0;

      if (id === 'rightSideBarRecla') {
        this.stepNumberExpert = 4;
        this.stepNumberAdvisor = 0;
        this.formExpert.setValue({
          messageExpert: '',
          subjectExpert: '',
          messageAdvisor: '',
          subjectAdvisor: '',
        });
        this.formExpert.get('messageAdvisor').enable();
        this.formExpert.get('subjectAdvisor').enable();
        this.formExpert.get('messageExpert').enable();
        this.formExpert.get('subjectExpert').enable();
      }
      this.id = id;
    });
  }

  newRequest() {
    this.stepNumberExpert = 4;
    this.stepNumberAdvisor = 0;
  }

  getCustomerFreeAttributeWithCodeM(
    accountDetails: AccountCustomerDetails
  ): CustomerFreeAttributes | undefined {
    return accountDetails.customerFreeAttributes.find(
      (attribute) => attribute.code === 'MCH'
    );
  }
  get message(): any {
    return this.form.get('message');
  }

  get subject(): any {
    return this.form.get('subject');
  }

  get messageExpert(): any {
    return this.formExpert.get('messageExpert');
  }

  get subjectExpert(): any {
    return this.formExpert.get('subjectExpert');
  }

  get messageAdvisor(): any {
    return this.formExpert.get('messageAdvisor');
  }

  get subjectAdvisor(): any {
    return this.formExpert.get('subjectAdvisor');
  }

  sendmailExpert() {
    let emailExpert = new EmailExpert();
    //  emailExpert.id = this.expert.id;
    emailExpert.body = this.messageExpert.value;
    emailExpert.subject = this.subjectExpert.value;
    emailExpert.id = 1;
    emailExpert.codeClient = this.customerNumberSelected;
    emailExpert.types = 'ASSISTANCE';
    emailExpert.email = 'nabil.harboul@socgen.com';
    /* emailExpert.email = this.expert.email;
   / emailExpert.types = this.expert.type;
    if (this.expert.alias != 'CCI' && this.expert.alias != 'CCM') {
      emailExpert.email = this.cafExpert.email;
    }*/

    this.advisorService
      .sendMailToAdviser(this.customerNumberSelected, emailExpert)
      .toPromise()
      .then(() => {
        this.sendCheck = true;
        this.stepNumber = 4;
        this.stepNumberExpert = 5;
        this.managerLabel = 'Experts';
        this.succesMessage = 'Votre message à été envoyé avec succès';

        this.formExpert.setValue({
          messageExpert: '',
          subjectExpert: '',
          messageAdvisor: '',
          subjectAdvisor: '',
        });
      })
      .catch(() => {
        this.sendCheck = true;
        this.stepNumber = 5;
        this.stepNumberExpert = 6;
        this.errorMessage = "le message n'a pas pu être envoyé";
      });
  }

  sendmailAdvisor() {
    let emailExpert = new EmailExpert();
    emailExpert.body = this.messageAdvisor.value;
    emailExpert.subject = this.subjectAdvisor.value;

    this.advisorService
      .sendMailToAdviser(this.customerNumberSelected, emailExpert)
      .toPromise()
      .then(() => {
        this.sendCheck = true;
        this.stepNumberAdvisor = 3;
        this.managerLabel = 'conseillers';
        this.succesMessage = 'Votre message à été envoyé avec succès';
        this.formExpert.setValue({
          messageExpert: '',
          subjectExpert: '',
          subjectAdvisor: '',
          messageAdvisor: '',
        });
      })
      .catch(() => {
        /*this.sendCheck = true;
        this.stepNumberAdvisor = 4;
        this.errorMessage = "le message n'a pas pu être envoyé";*/
        this.sendCheck = true;
        this.stepNumber = 5;
        this.stepNumberExpert = 6;
        this.errorMessage = "le message n'a pas pu être envoyé";
        this.formExpert.setValue({
          messageExpert: '',
          subjectExpert: '',
          subjectAdvisor: '',
          messageAdvisor: '',
        });
      });
  }

  out() {
    this.stepNumber = -2;
  }

  previews(step: number) {
    if (step != null && step == 3) {
      this.stepNumber = 0;
    }
    if (step != null && (step == 1 || step == 2)) {
      this.stepNumber--;
    }
    if (step != null && (step == 0 || step >= 4)) {
      this.stepNumber = -1;
    }
  }

  onChangeCAF(cafExpertId) {
    this.cafExpert = this.cafExperts.find(
      (cafExpert) => cafExpert.id == cafExpertId
    );
    this.suivant = false;
    let advisor = new Advisor();
    advisor.email = this.cafExpert.email;
    advisor.name = this.cafExpert.name;
    advisor.phoneNumber = this.cafExpert.phoneNumber;
    this.advisor = advisor;
    this.qrImageService
      .generateQR(advisor, this.width, this.height)
      .subscribe((qr) => {
        this.qrImage = qr;
        this.url = this.sanitizer.bypassSecurityTrustUrl(
          'data:image/;base64,' + this.qrImage.image
        );
      });
  }

  onChange(expertId) {
    this.expert = this.experts.find((expert) => expert.id == expertId);
    if (this.expert != null && this.expert != undefined) {
      this.formExpert.get('messageExpert').enable();
      this.formExpert.get('subjectExpert').enable();
      if (this.expert.alias != 'CCI' && this.expert.alias != 'CCM') {
        this.cafExpert = null;
        this.CafExpertService.findCafExpert(this.expert.type).subscribe(
          (cafExpert) => {
            this.cafExpertStatic = cafExpert;

            this.cafExpertStatic.forEach((value) => {
              value.cafLibelle = value.caf.libelle;
              value.cafId = value.caf.id + '';
            });
            this.cafExperts = this.cafExpertStatic;
          }
        );
      }

      if (this.expert.alias == 'CCI' || this.expert.alias == 'CCM') {
        this.stepNumberExpert = 4;
      }
    } else if (this.expert == null || this.expert == undefined) {
      this.cafExperts = [];
      this.formExpert.get('subjectExpert').disable();
      this.formExpert.get('messageExpert').disable();
    }
  }

  showHistorique() {
    this.advisorExpertHistoryService
      .findAdvisorExpertHistoryByCodeClient(
        this.page.currentPage,
        this.customerNumberSelected
      )
      .subscribe((result) => {
        this.page.totalPages = result.totalPages;
        this.page.totalElements = result.totalElements;
        this.advisorExpertHistorys = result.contentList;
      });
  }

  firstStep() {
    this.stepNumber = 0;
  }
  getAdvisorExpertHistoryPage() {
    this.advisorExpertHistoryService
      .findAdvisorExpertHistoryByCodeClient(
        this.page.currentPage,
        this.customerNumberSelected
      )
      .subscribe((result) => {
        this.page.totalPages = result.totalPages;
        this.page.totalElements = result.totalElements;
        this.advisorExpertHistorys = result.contentList;
      });
  }

  showDetailAdvisorExpertHistory(advisorExpertHistory: AdvisorExpertHistory) {
    const dialogRef = this.dialog.open(AssitanceDetailRequestComponent, {
      data: {
        subject: advisorExpertHistory.subject,
        message: advisorExpertHistory.message,
        numTicket: advisorExpertHistory.numTicket,
      },
    });
  }

  closeSideBar() {
    this.sidebarService.openSidebar('rightSideBar');
    this.askAdvisor = null;
    this.stepNumberAdvisor = 0;
    this.stepNumberExpert = 0;
    this.formExpert.setValue({
      messageExpert: '',
      subjectExpert: '',
      messageAdvisor: '',
      subjectAdvisor: '',
    });
    if (
      (this.customerFreeAttributes !== null &&
        this.customerFreeAttributes !== undefined &&
        this.customerFreeAttributes.alphaNumericValue == '04') ||
      (this.customerFreeAttributes !== null &&
        this.customerFreeAttributes !== undefined &&
        this.customerFreeAttributes.alphaNumericValue == '05')
    ) {
      this.askAdvisor = 0;
      this.showAdvisor = true;
    } else {
      this.showAdvisor = false;
      this.askAdvisor = 2;
      this.nextStep();
    }

    document.querySelector('[id="rightSideBar"]').classList.remove('active');
  }

  toggle(value: string) {
    if (this.isOpen == Number(value)) {
      this.isOpen = -1;
    } else {
      this.isOpen = Number(value);
    }
  }

  selectExpert(value: Expert, index: string) {
    this.myControl.setValue({ value: '' });
    this.stepNumberExpert = 1;
    this.expertSelectionner = value;
    this.onChange(value.id);
  }
  filterListExpert(typ) {
    if (typ == null || typ == undefined || typ == '') {
      this.cafExperts = this.cafExpertStatic;
    }
    let cafExpertsTest: CafExpert[];
    cafExpertsTest == null;

    cafExpertsTest = this.cafExperts.filter(
      (valeur: CafExpert) =>
        valeur.cafLibelle.toUpperCase().indexOf(typ.toUpperCase()) > -1
    );

    this.cafExperts = cafExpertsTest;
  }

  filter(val: string): string[] {
    return ['', ''];
  }
  lastStepExpert() {
    this.stepNumberExpert = 3;
  }
  sendRequestAdvisor() {
    this.stepNumberAdvisor = 0;
    this.askAdvisor = null;
  }
  sendRequest() {
    this.stepNumberExpert = 0;
    this.stepNumberAdvisor = 1;
  }

  back() {
    if (
      this.askAdvisor != null &&
      (this.askAdvisor == 3 || this.askAdvisor == 4) &&
      this.stepNumberExpert != null &&
      this.stepNumberExpert != 3
    ) {
      this.stepNumberExpert = this.stepNumberExpert - 1;
    }
    if (
      this.askAdvisor != null &&
      (this.askAdvisor == 3 || this.askAdvisor == 4) &&
      this.stepNumberExpert != null &&
      this.stepNumberExpert == 3
    ) {
      this.stepNumberExpert = 0;
    }

    this.formExpert.setValue({
      messageExpert: '',
      subjectExpert: '',
      messageAdvisor: '',
      subjectAdvisor: '',
    });
  }

  backAdvisor() {
    if (this.stepNumberAdvisor != null && this.stepNumberAdvisor == 2) {
      this.stepNumberAdvisor = 0;
      this.askAdvisor = null;
      return;
    }

    if (this.stepNumberAdvisor != null && this.stepNumberAdvisor > 0) {
      this.stepNumberAdvisor = this.stepNumberAdvisor - 1;
      if (this.stepNumberAdvisor === 0) {
        this.askAdvisor = null;
      }
    }
    if (
      this.stepNumberAdvisor != null &&
      (this.stepNumberAdvisor == 3 || this.stepNumberAdvisor == 4)
    ) {
      this.stepNumberAdvisor = 1;
    }
  }

  public nextStep() {
    if (this.stepNumberAdvisor == 1) {
      this.stepNumberAdvisor = 2;
    }
    this.formExpert.setValue({
      messageExpert: '',
      subjectExpert: '',
      subjectAdvisor: '',
      messageAdvisor: '',
    });
    if (this.askAdvisor == 1) {
      this.stepNumberAdvisor = 2;
      this.askAdvisor = 3;

      //  this.chargeAdvisor();
    }
    if (this.askAdvisor == 2) {
      this.askAdvisor = 4;
    }

    if (this.stepNumberAdvisor == 0) {
      this.stepNumberAdvisor = 1;
    }
  }
  public chargeAdvisor() {
    this.qrImageService
      .getQrCodeCAF(this.customerNumberSelected, this.width, this.height)
      .subscribe((qr) => {
        this.formExpert.get('messageAdvisor').enable();
        this.formExpert.get('subjectAdvisor').enable();
        this.formExpert.setValue({
          messageExpert: '',
          subjectExpert: '',
          subjectAdvisor: '',
          messageAdvisor: '',
        });
        this.qrImage = qr;
        this.url = this.sanitizer.bypassSecurityTrustUrl(
          'data:image/;base64,' + this.qrImage.image
        );
      });
  }
  public nextStep1() {
    this.stepNumberExpert = 0;
    // this.selectedIndex += 1;
    this.selectedIndex = 0;
    this.showHistorique();
    this.isOpen = 0;
    console.log('u historique  stepNumberExpert ', this.stepNumberExpert);
    console.log('u id ', this.id);
    console.log('u historique stepNumberAdvisor ', this.stepNumberAdvisor);
  }

  public previousStep() {
    this.stepNumberExpert = 0;
    this.selectedIndex = 1;
    this.showHistorique();
    this.isOpen = -1;
  }

  refresh() {
    this.stepNumberExpert = 0;
    this.showHistorique();
    this.formExpert.setValue({
      messageExpert: '',
      subjectExpert: '',
      messageAdvisor: '',
      subjectAdvisor: '',
    });
  }

  getCity(cafExpertId: any) {
    return this.cafExperts.find((cafExpert) => cafExpert.id === cafExpertId)
      .cafLibelle;
  }
  onTabClick(event: MatTabChangeEvent) {
    console.log('u historique  stepNumberExpert ', this.stepNumberExpert);
    console.log('u historique stepNumberAdvisor ', this.stepNumberAdvisor);
    const clickedTabLabel = event.tab.textLabel;
    if (clickedTabLabel === 'Nouvelle demande') {
      this.newRequest();
    }
    console.log('u historique  selectedIndex ', this.selectedIndex);
  }
}
